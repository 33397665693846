import React from "react";
import './Contact.css';
import facebookIcon from './Icons/facebook-icon.png';
import instagramIcon from './Icons/instagram-icon.png';
import linkedinIcon from './Icons/linkedin-icon.png';
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {
    const [state, handleSubmit] = useForm("xrbgadlp");
    if (state.succeeded) {
        // Sélection de la div par classe
        const divElement = document.querySelector('.form-tot');
        if (divElement) {
            // Création de l'élément h3
            const h3Element = document.createElement('h3');
            h3Element.className = 'confirmation-form';
            h3Element.textContent = "Mail Envoyé";
            // Remplacement de la div par le h3
            divElement.replaceWith(h3Element);
        }
    }

    return (
        <div className="contact-section">
            <div className="contact-form">
                <h2>Nous Contacter</h2>
                <form onSubmit={handleSubmit} className="form-tot">
                    <div className="form-group">
                        <label htmlFor="name">Nom</label>
                        <input type="text" id="name" name="name" required/>
                        <ValidationError prefix="Name" field="name" errors={state.errors}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required/>
                        <ValidationError prefix="Email" field="email" errors={state.errors}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="gsm">GSM</label>
                        <input type="tel" id="gsm" name="gsm" required/>
                        <ValidationError prefix="Gsm" field="gsm" errors={state.errors}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject">Objet</label>
                        <input type="text" id="subject" name="subject" required/>
                        <ValidationError prefix="Subject" field="subject" errors={state.errors}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" id="label-message">message</label>
                        <textarea id="message" rows="11" name="message" required></textarea>
                        <ValidationError prefix="Message" field="message" errors={state.errors}/>
                    </div>
                    <div className="button-container">
                        <button type="submit" disabled={state.submitting}>Envoyer</button>
                    </div>
                </form>
            </div>
            <div className="opening-hours">
                <h2>Horaires d'ouverture</h2>
                <table className="hours-table">
                    <tbody>
                        <tr>
                            <td className="row-left" >Lundi</td>
                            <td colSpan="2" className="closed">Fermé</td>
                        </tr>
                        <tr>
                            <td className="row-left">Mardi</td>
                            <td className="row-left">11h45 - 14h30</td>
                            <td className="row-right">17h45 - 22h00</td>
                        </tr>
                        <tr>
                            <td className="row-left">Mercredi</td>
                            <td className="row-left">11h45 - 14h30</td>
                            <td className="row-right">17h45 - 22h00</td>
                        </tr>
                        <tr>
                            <td className="row-left">Jeudi</td>
                            <td className="row-left">11h45 - 14h30</td>
                            <td className="row-right">17h45 - 22h00</td>
                        </tr>
                        <tr>
                            <td className="row-left">Vendredi</td>
                            <td className="row-left">11h45 - 14h30</td>
                            <td className="row-right">17h45 - 22h00</td>
                        </tr>
                        <tr>
                            <td className="row-left">Samedi</td>
                            <td className="row-left">11h45 - 14h30</td>
                            <td className="row-right">17h45 - 22h00</td>
                        </tr>
                        <tr>
                            <td className="row-left">Dimanche</td>
                            <td className="row-left">11h45 - 14h30</td>
                            <td className="row-right">17h45 - 22h00</td>
                        </tr>
                    </tbody>
                </table>
                <div className="social-medias">
                    <div className='social-button-contact' onClick={() => window.open('https://www.facebook.com/@sozosushi/', '_blank')}>
                        <img src={facebookIcon} alt="Facebook"/>
                    </div>
                    <div className='social-button-contact' onClick={() => window.open('https://www.instagram.com/sozo_sushi/', '_blank')}>
                        <img src={instagramIcon} alt="Instagram"/>
                    </div>
                    <div className='social-button-contact' onClick={() => window.open('https://www.linkedin.com/company/sozo-sushi/','_blank')}>
                        <img src={linkedinIcon} alt='Linkedin'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;