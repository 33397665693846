import React from "react";
import "./Footer.css";

const Footer = () => {
    return(
        <div className="footer">
            <h4>© sozo sushi</h4>
        </div>
    );
};

export default Footer;